<template>
  <div
    class="w-9/12 flex flex-col-reverse md:flex-row justify-center items-center gap-6 mx-auto mt-32"
  >
    <div class="md:w-2/5 text-sm lg:text-lg">
      <h2 class="text-darkblue-500 font-bold text-xl lg:text-3xl">
        <slot name="header"></slot>
      </h2>
      <p class="leading-5 text-paragraphGray">
        <slot></slot>
      </p>
    </div>
    <div class="md:w-3/5">
      <slot name="image"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "textImage"
};
</script>

<style lang="scss" scoped></style>
